.curtain {
  overflow: hidden;
  position: relative;
}

/* Initial states for each orientation */
.curtainBottom {
  clip-path: inset(0 0 100% 0);
  animation: revealFromBottom 2s ease forwards;
}

.curtainBottomReverse {
  clip-path: inset(0 0 0 0);
  animation: hideFromBottom 2s ease forwards;
}

.curtainTop {
  clip-path: inset(100% 0 0 0);
  animation: revealFromTop 2s ease forwards;
}

.curtainTopReverse {
  clip-path: inset(0 0 0 0);
  animation: hideFromTop 2s ease forwards;
}

.curtainLeft {
  clip-path: inset(0 100% 0 0);
  animation: revealFromLeft 2s ease forwards;
}

.curtainLeftReverse {
  clip-path: inset(0 0 0 0);
  animation: hideFromLeft 2s ease forwards;
}

.curtainRight {
  clip-path: inset(0 0 0 100%);
  animation: revealFromRight 2s ease forwards;
}

.curtainRightReverse {
  clip-path: inset(0 0 0 0);
  animation: hideFromRight 2s ease forwards;
}

/* Keyframes */
@keyframes revealFromBottom {
  to {
    clip-path: inset(0 0 0 0);
  }
}

@keyframes hideFromBottom {
  to {
    clip-path: inset(0 0 100% 0);
  }
}

@keyframes revealFromTop {
  to {
    clip-path: inset(0 0 0 0);
  }
}

@keyframes hideFromTop {
  to {
    clip-path: inset(100% 0 0 0);
  }
}

@keyframes revealFromLeft {
  to {
    clip-path: inset(0 0 0 0);
  }
}

@keyframes hideFromLeft {
  to {
    clip-path: inset(0 100% 0 0);
  }
}

@keyframes revealFromRight {
  to {
    clip-path: inset(0 0 0 0);
  }
}

@keyframes hideFromRight {
  to {
    clip-path: inset(0 0 0 100%);
  }
}
